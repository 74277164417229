
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss";

// Colours
$red: #E2231A;
$sidebar-bg: #e6e6e6;

$grid-float-breakpoint: $screen-md-min;

// Body
$body-bg: #fff;

// Borders
$laravel-border-color: darken($body-bg, 10%);
$list-group-border: $laravel-border-color;
$navbar-default-border: $laravel-border-color;
$panel-default-border: $laravel-border-color;
$panel-inner-border: $laravel-border-color;

// Brands
$brand-primary: #E2231A;
$brand-info: #8eb4cb;
$brand-success: #2ab27b;
$brand-warning: #cbb956;
$brand-danger: #bf5329;

// Typography
$font-family-base: Verdana, sans-serif;
$font-family-sans-serif: Verdana, sans-serif;
$font-size-base: 14px;
$line-height-base: 1.3em;
$text-color: #636b6f;

// Headings
$headings-font-family: "VAG-Rounded-Bold", Arial, sans-serif;
$headings-font-weight: normal;
$headings-color: #58595B;

// Navbar
$navbar-height: 50px; 
$navbar-default-bg: $brand-primary;
$navbar-default-brand-color: #fff;
$navbar-default-border: $navbar-default-bg;
$navbar-default-brand-hover-color: darken($navbar-default-brand-color, 10%);

$navbar-default-link-color: #fff; 
$navbar-default-link-hover-color: darken($navbar-default-link-color, 10%);
// $navbar-padding-horizontal: 30px;
$navbar-default-link-active-bg: $gray-light; 
$navbar-default-link-active-color: #fff;

$navbar-default-toggle-hover-bg: #fff;
$navbar-default-toggle-border-color: #fff;
$navbar-default-toggle-icon-bar-bg: #fff;
$navbar-default-toggle-hover-bg: #fff;

$border-radius-base: 0;

.navbar-default .navbar-toggle .icon-bar { background-color: $red; }
 

$navbar-border-radius: 0; 

// Pagination

$pagination-color: $gray-light;
$pagination-hover-color: $gray-light;
$pagination-active-bg: $gray-light;

// Inputs
$input-border-radius: 0;