
// Fonts
// @import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);
@import url(https://fonts.googleapis.com/css?family=Varela+Round);

@import url('https://fonts.googleapis.com/css?family=Orbitron:500'); // calculator font

@font-face {
	font-family: 'Calculator Font';
	src: url('/fonts/digital-7.regular.ttf') format('truetype'); // Custom calc font
}

@font-face {
	font-family: 'VAG-Rounded-Bold';
	src: url('/fonts/VAG-Rounded-Bold.ttf') format('truetype');
}

@font-face {
	font-family: 'Gotham-Book';
	src: url('/fonts/Gotham-Book.ttf') format('truetype');
}

// Variables
@import "variables";

h1,h2,h3 { font-family: "VAG-Rounded-Bold", serif !important; }

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";


.red { color: $red; }

a:hover, a:focus { text-decoration: none; }

// .example {
//     @include gradient-vertical(#fff, #000, 0%, 100%);
// }

.ht-main-btn {
    @include button-variant(#fff, $red, $red);
    @include button-size(25px, 25px, 22px, 1em, 0);
}

.page-header { margin-top: 0; }


// Colors
$red: #E2231A;

.btn-white {
  @include button-variant($red, #fff, #fff);
  font-size: 18px;

  &:focus,
	&.focus {
		color: $red;
		background-color: white;
	}
	&:hover {
		color: $red;
		background-color: darken(white, 10%);
	}
}
.btn-red {
  @include button-variant(#fff, $red, $red);
  font-size: 18px; 
}
.btn-calc {
	color: $red;
	background-color: white;
	border-color: $red;

	&:focus,
	&.focus {
		color: $red;
		background-color: white;
	    border-color: $red;
	}
	&:hover {
		color: $red;
		background-color: darken(white, 10%);
		border-color: $red;
	}
	&:active,
	&.active,
	.open > &.dropdown-toggle {
		color: white;
		background-color: $red;
	    border-color: white;
	}
}

.btn 			{ border-radius: 0px; text-transform: uppercase; font-family: "VAG-Rounded-Bold", Verdana, sans-serif; }

// Make the large size not so ridiculously big on mobile
@media (min-width: $screen-md-min) {
	.btn-lg			{ font-size: 30px; padding: 15px 26px; }
}

// to vertically center column content
.vcenter {
    display: inline-block;
    vertical-align: middle;
    float: none;
}

div.welcome { 
	background: transparent url('/images/header_bg.jpg') no-repeat top center;
	background-size: cover;
	min-height: 100%;
	

	h2,h1		{ font-size: 65px; padding-top: 270px; color: #fff; text-align: center; text-shadow: 3px -3px 10px rgba(0,0,0,.8); }
	a.btn 		{ margin-top: 35px; }

	div.logo	{ height: 150px; width: 150px; margin-top: 30px; }
	div.social-icons {
		margin: 60px 0 30px -15px;

		a 			{  color: white; margin-left: 7px; width: 50px; height: 50px; padding-top: 11px; border-radius: 50%;  }
		
	}
}

#myCarousel {
	div.carousel-caption {
		max-width: $screen-md-max;
		margin-left: auto;
		margin-right: auto;

		h1, h2 { font-size: 78px; color: #fff; text-align: center; text-shadow: 3px -3px 10px rgba(0,0,0,.8); }
		a.btn { margin-top: 35px; }

		div.social-icons {
			margin: 60px 0 30px 0;

			.fa-stack { width: 3em; height: 3em; line-height: 3em; }
			.fa-stack-2x { font-size: 3em; }
			.fa-stack-1x { font-size: 1.5em; }

			// shrink icons down a bit to save space
			$fa-stack-2x: 2em;
			@media (max-width: $screen-sm-max)
			{
				margin-top: 30px; 
				margin-bottom: 20px;

				.fa-stack { width: $fa-stack-2x; height: $fa-stack-2x; line-height: $fa-stack-2x; }
				.fa-stack-2x { font-size: $fa-stack-2x; }
				.fa-stack-1x { font-size: $fa-stack-2x / 2; }
			}

			a {  color: $red; }
		}

		@media (min-width: 1441px) {
			h1, h2 { font-size: 92px; }
		}

		@media (min-width: $screen-md-min) {
			padding-top: 170px;
		}

		@media (max-width: $screen-sm-max) and (min-height: 568px) {
			top: 10% !important;
		}

		@media (max-width: $screen-xs-max) {
			h1, h2	{ font-size: 51px; padding-top: 35px; margin-left: 15px; margin-right: 15px; }
			a.btn { margin-top: 15px; }

			// stretch div al the way out to the left and right as the arrows are gone
			left: 0; right: 0;
		}
	}
}



div.map {
	margin-top: 35px;
	margin-bottom: 20px;
	min-height: 750px;

	background-image: url('/images/welcome_map.svg');
	background-repeat: no-repeat;
	background-position: center;

	h2 			{ font-size: 51px; margin: 20px 0 50px; color: $red; text-align: center;  }
	h3 			{ font-size: 32px; color: $gray-light; text-align: center;  }
	p 			{ font-size: 26px; color: $red; text-align: center; font-family: "VAG-Rounded-Bold", Verdana, sans-serif; line-height: 1.6em; }

	@media (min-width: $screen-md-min) {
		h2 { font-size: 65px; }
		.btn 		{ margin-top: 40px; }	
	}
}

@media (max-width: $screen-sm-max)
{
	div.map {
		div.col-md-4 { margin-bottom: 50px; }
		h3 { margin-top: 30px; }
		margin-bottom: -50px;
	}
}

@media (max-width: $screen-sm-min)
{
	div.map { background-size: 150%; }
}
@media (max-width: $screen-xs-min)
{
	div.map { background-size: 250%; }
}

// all blog images are responsive
div.blog-body-text, div.info-category-description {
	& img {
		@extend .img-responsive;
	}
}

div.blog-item {
	margin-bottom: 40px;

	h3 			{ margin-top: 0; }
	h3, h4 		{ font-weight: normal; }
	a  			{ text-decoration: none; color: $gray-light; }
	a h4		{ text-decoration: underline; }
}

@media (max-width: $screen-xs-min) {
	div.blog-item {
		h3		{ margin-top: 20px; }
	}
}

// Blog preview
div.blog-preview-container {
	h2 			{ font-size: 65px; margin: 20px 5px 50px 5px; color: $gray-light; text-align: center; }
	h3 			{ font-size: 22px; color: $red; margin-bottom: 5px; }
	p 			{ margin: 0; color: $gray-light; }
	p.date 		{ color: $gray-light; }
	.thumbnail 	{ border: none; padding: 0; margin-bottom: 0; }
}
@media (max-width: $screen-md-min) {
	div.blog-preview-container {
		h2 { font-size: 51px; }
		.col-sm-4 {
			margin: 30px 0;
		}

	}
}

div.ad {
	img { width: 100%; height: 100%; }
	margin-bottom: 40px; 
}

div.author-info {
	margin-top: 25px;
	margin-bottom: 25px; 
	display: flex;
	align-items: center;

	div.line-date,
	div.line-name {
		margin-top: 16px; 
	}

	div.line-picture { 
		// > img { margin: auto; }
	}

	div.line-date { display: none; }

	@media(min-width: $screen-sm-min) {
		div.line-date { 
			display: block; 
			> p { text-align: right; }
		}
	}

	// @media (max-width: $screen-xs-max) {
	// 	div.line-name p, 
	// 	div.line-date p { text-align: center; }
	// }
}

div.info-item {
	margin-bottom: 40px;

	h3 			{ margin-top: 0; }
	h3, h4 		{ font-weight: normal; }
	a  			{ text-decoration: none; color: $gray-light; }

	ol { padding-left: 1em; }
}

@media (max-width: $screen-xs-min) {
	div.info-item {
		h3		{ margin-top: 20px; }
	}

	div.related-post { margin-top: 25px; }
	div.related-post:first-child { margin-top: 0; }
}

div.info-container {
	div.info-article-content img {
		width: 100%;
		height: auto;
	}
}


// Check eligibility
div.check-el-container { background: $red; margin-top: 50px; }
div.check-el { 
	padding: 70px 0 0;

	h1,h2	{ font-size: 68px; color: #fff; }
	p 		{ font-size: 30px; color: #fff; line-height: 1.3em; }
	a.btn 	{ margin-top: 25px; }
}

div.check-el-bottom { 
	height: 90px; width: 100%; 
	background: transparent url('/images/check-el-background.svg') no-repeat bottom center; 
	background-size: 110%;
	margin-bottom: 50px; 
}

@media (max-width: $screen-md-min) {
	div.check-el {	
		padding: 25px 0 0;

		h1, h2	{ font-size: 51px; color: #fff; margin-left: 5px; margin-right: 5px;  }
		p 		{ font-size: 30px; color: #fff; margin-left: 10px; margin-right: 10px; }
		a.btn 	{ margin-top: 25px; }

		div.clock-container img { width: 45%; margin: 15px auto; }
	}

	div.check-el-bottom { 
		height: 33px; 
		margin-bottom: 15px;
	}
}

// Piggy
div.piggy { 
	h1, h2 	{ font-size: 68px; color: $red; }
	p 		{ font-size: 30px; color: $red; line-height: 1.3em; }
	a.btn 	{ margin-top: 25px; }
}

@media (max-width: $screen-md-min) {
	div.piggy {
		padding: 0 0 0;

		h1, h2 		{ font-size: 51px; color: $red; margin-left: 5px; margin-right: 5px; }
		p 			{ font-size: 28px; color: $red; margin-left: 10px; margin-right: 10px;}

		div.piggy-sm-container img { width: 45%; margin: 15px auto; }
	}
}


// Navbar
@media (min-width: $grid-float-breakpoint)
{
	.navbar  { min-height: 110px; padding-top: 30px; }
}
a.navbar-brand { font-family: "VAG-Rounded-Bold", Verdana, sans-serif; }

.navbar-default { border: none; }

.navbar-default .navbar-toggle .icon-bar { width: 30px; height: 4px; }
.navbar-default .navbar-toggle { margin: 0; }

.navbar-default .navbar-nav > li > a 	{ color: #fff; font-family: 'Gotham-Book'; font-size: 16px; }
.navbar-default .navbar-nav > li > a:hover, 
.navbar-default .navbar-nav > li > a:focus { color: #fff; }

.navbar-default .navbar-nav > .open > a, 
.navbar-default .navbar-nav > .open > a:hover, 
.navbar-default .navbar-nav > .open > a:focus		{ background: #fff; color: $red; }

.dropdown-menu										{ background: $gray-light; border: none; }
.dropdown-menu > li > a 							{ color: #fff; line-height: 1.6em; }
.dropdown-menu > li > a:hover, 
.dropdown-menu > li > a:focus						{ background: transparent; }

.navbar-default li.dropdown-search ul.dropdown-menu {
	background-color: white;
}
.navbar-default li.dropdown-search .form-group { 
	width: 300px;
	margin: 0;
	padding: 0;
}
.navbar-default li.dropdown-search input {
	border-color: transparent;
	outline: none;
	box-shadow: none; 
}

.navbar-default .collapsed-buttons .hamburger {
	outline: none;
	padding: 14px 9px;
	position: relative;
	float: right; 
	border: 1px solid transparent;
	border-left: 1px solid #f36767;
	background-color: $red;
    background-image: none;
    
	& .icon-bar {
		width: 30px; 
		height: 4px;
		background-color: white;
		display: block;
		border-radius: 1px;
	}

	& .icon-bar + .icon-bar {
		margin-top: 4px;
	}
}


.navbar-default .collapsed-buttons {
	float: right;
	margin-right: -15px;
}

.navbar-default .collapsed-buttons .btn-search {
	outline: none;
	padding: 1px 5px;
	position: relative;
	float: right; 
	color: $red;
	border: 1px solid transparent;
	background-color: white;
    background-image: none;
    margin-top: 13px;
    margin-right: 13px;
    border-radius: 12px;
}

.navbar-default .navbar-form-apply a { font-size: 14px; }

.navbar-form { padding: 0 0 0 15px; }



@media (min-width: $grid-float-breakpoint) {
	.navbar-default .collapsed-buttons .hamburger { display: none; }
}

@media (min-width: $grid-float-breakpoint) {
	li.hidden-apply-link { display: none; }
	.navbar-nav .dropdown-menu { opacity: 0.9; }
	.navbar-nav .dropdown-menu { right: inherit; }
}

// fix dropdown menu not displaying correctly
@media (max-width: $grid-float-breakpoint) {
	.navbar-nav .open .dropdown-menu {
		position: static;
	    float: none;
	    width: auto;
	    margin-top: 0;
	    background-color: transparent;
	    border: 0;
	    box-shadow: none;
	}
}

@media (max-width: $grid-float-breakpoint - 1) {
	.navbar-default .navbar-nav 		{ margin-bottom: 0; margin-top: 0; }

	.navbar-default .navbar-form-apply { display: none; }

	.navbar-default .navbar-nav > li 				{ border-bottom: 1px solid #f36767; }
	.navbar-default .navbar-nav > li:first-child 	{ border-top: 1px solid #f36767; }
	// all navs
	.navbar-nav > li > a 				{ text-align: center; }
		

	.navbar-default li.dropdown-search .form-group {
		width: 100%;
	}

	.navbar-default li.dropdown span.caret { display: none; }
	.navbar-default .navbar-nav > li > a::after {
		content: ' - ';
		color: transparent;
	    border-color: #f36767;
	    padding: 10px 0;
	    float: right;
	    margin-top: -10px;
	    margin-right: -15px;
	    width: 50px;
	    height: 100%;
	    display: inline-block;
	    text-align: center;
	    border-width: 0 0 0 1px;
	    border-style: solid;
	    font-weight: normal;
	}

	.navbar-default .navbar-nav > li > a.dropdown-toggle::after {
	    content: ' + ';
	    color: white;
	}
	.navbar-default .navbar-nav > li.open > a.dropdown-toggle::after {
	    content: ' - ';
	    color: white;
	}

	.navbar-default .navbar-nav > .open > a, 
	.navbar-default .navbar-nav > .open > a:hover, 
	.navbar-default .navbar-nav > .open > a:focus				{ background: $red; color: white; }

	.navbar-default .navbar-nav .open .dropdown-menu 			{ background-color: white; }
	.navbar-default .navbar-nav .open .dropdown-menu > li > a 	{ color: #999; font-size: 85% }
}


.navbar-default .navbar-brand			{ 
	font-size: 30px; 
	font-weight: bold; 
	line-height: 26px; // compensate for font being screwed
}

// WELCOME Navbar
nav.navbar-welcome {
	background-color: transparent; 
	transition: all 0.3s;

	a.navbar-brand { width: 150px; height: 150px; padding: 0; margin: 25px 0; }

	#app-navbar-collapse { 
		margin-top: 50px; 

		li a 			{ color: #fff; }
		.nav .open > a, 
		.nav .open > a:hover, 
		.nav .open > a:focus 	{ background-color: $red; }

		.nav > li > a:hover, 
		.nav > li > a:focus		{ background-color: $red; }

		.dropdown-menu { background-color: $gray; }

		.dropdown-menu > li > a:hover, 
		.dropdown-menu > li > a:focus		{ background-color: $red; }
	}
}

nav.navbar-default.shrink {
	background: $red; 

	a.navbar-brand-lg { display: none; }
	a.navbar-brand { display: block; }
	transition: all 0.3s;
}
nav.navbar-default {
	transition: all 0.3s;
}

nav.navbar-welcome.shrink { 
	background: white;

	a.navbar-brand { display: none; }

	 #app-navbar-collapse {
		margin-top: 0;
		margin-bottom: 0;

		li a 			{ color: $red; }
		.nav .open > a, 
		.nav .open > a:hover, 
		.nav .open > a:focus 	{ background-color: white; }

		.nav > li > a:hover, 
		.nav > li > a:focus		{ background-color: $red; }

		.dropdown-menu { background-color: $gray; }

		.dropdown-menu > li > a:hover, 
		.dropdown-menu > li > a:focus		{ background-color: $red; }
	}

	.navbar-toggle { 
		border: 1px solid $red; 

		span.icon-bar { background-color: $red; }
	}
}


// Footer
$footer-bg: #58595B;

footer { 
	margin-top: 50px; 
	position: static;
	bottom: 0;

	h2 						{ font-size: 25px; margin-bottom: 25px; color: white; }
	.btn 					{ padding: 8px 30px; }
	a, a:hover, a:focus		{ color: #fff; }

	p.more a { font-weight: bold; }

	div.footer-top {
		a { 
			height: 38px; 
			width: 38px; 
			background: url('/images/back-to-top.svg') no-repeat center; 
			display: inline-block; 
			float: right; 
		}
	}
}


div.footer-middle { 
	background-color: $footer-bg; 
	margin-top: 20px; 
	color: #fff;
	padding: 47px 0 20px;

	ul 				{ padding: 0 0 0 0; width: 210px; }

	li 					{ list-style-type: none; line-height: 2em; }
	.fa 				{ color: $red; }
	.fa-caret-right 	{ padding-right: 7px; vertical-align: middle; font-size: 1.5em; }
	li > a, p.more > a 	{ line-height: 1.5em; vertical-align: middle; }

	.form-group { margin-bottom: 26px; }

	.social-icons { text-align: left; margin-top: 20px;  }
	.social-icons a > .fa-inverse { color: white; }
}

div.footer-bottom { 
	margin-top: 30px; 
	

	div.text-right {  color: #ababab; }
	div.text-right a { color: #fff; }

	@media (max-width: $screen-xs-max) {
		a, p { font-size: 12px; margin: 0;  }
		text-align: center;
		.text-right { text-align: center; }
	}
}

@media (max-width: $screen-md-min) {
	footer { 
		h2 { text-align: center; line-height: 80px; margin-bottom: 0; }

		div.footer-top {
			height: 22px;
			background-color: $red;
			text-align: center;

			a { float: none; background: url('/images/back-to-top-btn-xs.svg'); margin-top: -16px; }
		}

		div.col-md-4 {
			border-bottom: 1px solid $gray-light;
			padding-bottom: 25px;
		}

		div.col-md-4:last-child {
			border-bottom: none;
		}

		div.col-md-4, 
		div.col-md-6 {
			padding-left: 35px; 
			padding-right: 35px; 
		}

		div.footer-middle { 
			padding-top: 0; margin-top: 1px; 
			ul { margin: 0 auto; }

			div.social-icons { margin-top: 15px; text-align: center; }
		}

	}
}


// Side bar

html, body { height: 100%; }
.row-eq-height { display: -webkit-box; display: -webkit-flex; display: -ms-flexbox; display: flex; }

div.sidebar {
	background-color: $sidebar-bg;
	padding-bottom: 15px;
	padding-top: 15px;
	max-width: 300px;
	margin: 0 auto;

	// only do this for md and lg, don't know 
	// I want it included on xs/sm yet

	@media (min-width: $screen-md-min) {
		float: right;
		min-width: 285px !important;
		width: 285px;
	}


	h3 { margin-top: 40px; margin-bottom: 15px; }
	
	a { text-decoration: none; color: #939598; }

	img { max-width: 262px; display: block; margin: 0 auto; border: none; }

	.list-group-item { border-color: transparent; background-color: transparent; padding: 0; }

	.sidebar-tabs { 
		background-color: white; 
		margin-bottom: 25px; margin-left: auto; margin-right: auto; 
		// max-width: 300px;

		@media (min-width: $screen-md-min) {
			padding: 25px;
			margin-bottom: 0;
		}

		.nav-tabs {
			border-color: transparent; 
			> li { width: 50%; }

			> li > a { 
				padding: 10px 0; 
				text-align: center;

				text-transform: uppercase; 
				font-weight: bold; 
				color: white; 
				background-color: $sidebar-bg; 
			}

			> li.active > a { background-color: $red; border: 1px solid $red; }
		}

		ol { padding-left: 20px; margin-top: 25px;  }
		.list-group-item { border-color: white; background-color: white; text-align: left; }
	}

	@media (max-width: $screen-sm-max) {
		// text-align: center;
		background-color: transparent;

		> img { margin-bottom: 25px; }
	}
}

div.app .nav { margin-bottom: 0; }

// bordered columns
@media (min-width: $screen-md-min) {
	.col-border::before {
		content: "";
		position: absolute;
		top: 0;
		bottom: 0; 
		border-left: 1px solid $gray-light;
	}

	.row-relative { position: relative; }
	.col-border-padding { padding-left: 15px; }

	.col-border {
		padding-left: 0;
		position: static;
	}
}


// ebooks list
div.ebook,
div.lesson-plan { 
	margin-bottom: 15px; padding-bottom: 15px; 

	h2, h3	{ margin-top: 0; }
	p.note { font-size: 80% }
}

@media (max-width: $screen-sm-min) {
	div.ebook h3, 
	div.lesson-plan h3 { margin-top: 20px; }
}

// Check eligibility page
div.check-eligibility-container {
	.xs-labels { display: none; }
}
div.radio-question { 
	padding: 15px; 
	font-size: 120%;
}
div.radio-big { 
	padding: 15px; 

	// Get rid of the null label added by prettyCheckable.js
	label 		{ display: none; }
}

@media (max-width: $screen-xs-min) {
	div.check-eligibility-container {
		div.el-questions { margin-bottom: 15px; padding-bottom: 15px; }
		
		.xs-labels {
			display: block;
			text-align: center;
		}
	}
}

ul.pagination {
	a, span { font-family: VAG-Rounded-Bold, sans-serif; text-transform: uppercase;  }
	li.disabled { display: none; }

	li.active a, 
	li.active span { background-color: #58595B; }

	li a[rel="next"], 
	li a[rel="prev"] { background-color: #58595B; color: #fff; }
}

.pagination a, .pagination span {
	border-color: transparent !important; 
}

// El-no
div.el-no-container {
	div.el-no-img-container { margin: 45px 0; }
	div.el-no-img-container img { width: 40%; }

	div.el-no-reasons p {
		text-align: center;
		font-size: 18px;
		margin: 1.2em 0;
		color: $gray-light;

		&.go-away { color: $red; font-weight: bold; }
	}
}

@media (max-width: $screen-sm-min) {
	div.el-no-container {
		div.el-no-img-container img { width: 100%; } 

		div.el-no-reasons p { font-size: 14px; }
	}
}

// El-yes
div.el-yes-container {
	div.el-yes-img-container { margin: 45px 0; }
	div.el-yes-img-container img { width: 40%; }

	div.el-yes-info 
	{
		padding: 45px 0; 
		text-align: center;
		
		p {

			font-size: 18px;
			margin-top: 45px;
			color: $gray-light;
		}
	}
}

@media (max-width: $screen-sm-min) {
	div.el-yes-container {
		div.el-yes-img-container img { width: 100%; } 

		div.el-yes-info p { font-size: 14px; }
	}
}

// Apply Pages
// step 1
div.apply-step1-container,
div.apply-step2-container,
div.apply-step3-container 
{
	div.navigation-buttons {
		margin-top: 60px; 
		a:first-child { margin-right: 10px; }
	}

	.fa-question-circle 	{ color: $red; }

	div.progression 
	{
		text-align: center;
		padding: 15px 0;

		img 		{ height: 55px; }		
	}

	// div.form-group p { font-size: 14px; }

	a.file-upload { font-size: 14px; letter-spacing: 1px; }
}

div.apply-step2-container,
div.apply-step3-container {
	div.progression { padding: 45px 0 15px 0; }
}

div.my-progress-bar {
	text-align: center;
	margin: 50px 0;

	.step {
		display: inline-block;
		padding: 7px 30px;
		text-align: center;
		background-color: $sidebar-bg;
		color: transparent;
	}
	.step:first-child {
		border-radius: 25px 0 0 25px;
	}
	.step:last-child {
		border-radius: 0 25px 25px 0;
	}
	.step.active {
		background-color: $red;
		color: white;
	}

	@media (max-width: $screen-xs-min) {
		.step { padding-left: 20px; padding-right: 20px; }
	}
}

div.apply-step2-container {
	label.control-label { margin-bottom: 15px; }

	input.form-control {
		background-color: transparent;
    	border-color: transparent;
    	box-shadow: none;
    	padding: 17px 10px;
	}

	div.upload-info {
		
	}
}

// Thanks Page
div.apply-thanks-container 
{
		li 			{ margin-top: 20px; }
		div.apply-thanks-img-container 
		{ 
			margin: 45px 0; 
			img 		{ width: 40%; }
		}

	div.apply-thanks-btn-container {
		padding: 50px 0;
		display: table;
		margin: 0 auto;

		a.apply-thakns-btn-1 img 	{ height: 35px; }
		a.apply-thakns-btn-2 img 	{ height: 35px; margin-top: 15px; }
	}
}
@media (max-width: $screen-sm-min) {
	div.apply-thanks-container {
		div.apply-thanks-img-container img { width: 80%; } 
	}
}

// Salary Calculator
div.salary-container {
	div.calc-container {
		&::before, &::after {
			content: " ";
			display: table;
		}

		position: relative;
		clear: both;
		max-width: 360px;
		margin: 30px auto;

		text-transform: uppercase;

		label.btn-calc { 
			border-radius: 5px !important; 
			font-size: 16px; 
			padding: 12px 6px !important;
			line-height: 1;
		}
	}

	div.calc-screen {	
		background-color: $red; 
		border-radius: 10px 10px 0 0; 
		padding: 25px; 

		> div.display {
			background-color: white;
			padding: 10px; 
			font-family: 'Calculator Font', sans-serif;
			line-height: 1;

			> div.line-big, > div.line-small {
				width: 100%; 
				height: auto;
				color: $red; 
				text-align: center; 

			}
			> div.line-big { font-size: 4em; font-weight: bold; }
			> div.line-small { text-align: left; }
		}
	}

	div.calc-body {
		background-color: $red; 
		border-radius: 0 0 10px 10px; 
		padding: 15px;
		margin-top: 7px; 
		display: table;
		width: 100%;

		> div.calc-body-header {
			width: 100%; 

			div.calc-brand {
				background-image: url('/images/logo_welcome.svg');
				background-repeat: no-repeat;
				background-size: contain;

				width: 80px;
				height: 80px;
				float: left;
				margin-top: -9px;
				margin-top: -9px;
			}

			div.calc-status {
				background-color: white;
				color: $red;
				text-align: center;

				margin-top: 15px;
				padding-top: 11px;
				width: 50px;
				height: 50px;
				float: right;
			}
		}

		> div.calc-school-btns {
			margin-top: 15px;
		}

		> div.calc-currency-btns {
			margin-top: 15px;

			> p { color: white; }

			label.btn-calc { margin-bottom: 4px; }

			> .btn-group > .btn:not(:last-child) {
				margin-right: 4px;
			}
		}
	}

	div.salary-btn-container {
		padding: 50px 0;
		display: table;
		margin: 0 auto;
		clear: both;

		a.salary-btn-1 img 	{ height: 35px; }
		a.salary-btn-2 img 	{ height: 35px; margin-top: 15px; }
	}

	div.extra-info {
		text-align: center;

		& .btn { margin-top: 25px; margin-bottom: 75px; }
	}
}

@media (max-width: $screen-sm-min) {
	div.salary-container div.calc-screen > div.display > div.line-big { font-size: 3em; }
}

.st-social-icons {
	text-align: right;
	float: right; 
}


.carousel-inner > .item > img, .carousel-inner > .item > a > img { 
  /* Set rules to fill background */
  min-height: 100%;
  min-width: 1024px;
	
  /* Set up proportionate scaling */
  width: 100%;
  height: auto;
}

@media screen and (max-width: 1628px) { /* Specific to this particular image */
  	.carousel-inner > .item > img, .carousel-inner > .item > a > img {
  		left: 50%;
    	margin-left: -814px;   /* 50% */
  	}
 }

div.search-container {
	a.search-title, a.search-more { color: $red; }
	a.search-title { font-size: 16px; }

	div.search-results-container {
		margin-top: 30px; 
	}
}

@media (max-width: $screen-sm-max) {
	body, p,a,li { font-size: 16px; }
}

div.sitemap-container {
	
}

// make FAQ accordian stretch
div.panel-heading h3.panel-title > a {
	display: block; width: 100%; font-family: Verdana, sans-serif; 
}

// FAQ accordion
div.faq-question {
	margin-bottom: 20px;
	border: 1px solid #e6e6e6;
	background-color: #f5f5f5;
	padding: 10px 15px;
	color: #333;
	font-size: 16px;
	display: block; 
	width: 100%; 
	font-family: Verdana, sans-serif;
}

div.faq-answer {
	background: #fff;
}

// Newsletter Modal
div.g-recaptcha { width: 304px; margin: 0 auto 15px; }


div.modal-body.subscribe-form {  }
div.modal-body.subscribe-success { 
	text-align: center;

	span.fa { font-size: 7em; color: $red; }
	p.message { margin: 25px 0; }
}

div.modal-body { text-align: center; }
