.progress-bar {
	// for some reason this isn't working as it should?
	// overflow: hidden;

	> div { height: 46px; width: 201px; background-repeat: no-repeat; float: left; }

	.step-1 { background-image: url(../images/sprites/step_1_active.png); width: 198px; }
	.step-2 { background-image: url(../images/sprites/step_2_disabled.png); }
	.step-3 { background-image: url(../images/sprites/step_3_disabled.png); }

	.step-1.completed { background-image: url(../images/sprites/step_1_active.png); }
	.step-2.completed { background-image: url(../images/sprites/step_2_active.png); }
	.step-3.completed { background-image: url(../images/sprites/step_3_active.png); }
}